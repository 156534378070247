import React, { useRef } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";
import { useEffect, useState } from "react";
import Cadopslogo from "../../../Content/Images/Logo/cadopslogo.svg";
import { ReactComponent as MyTrainingIcon } from "../../../Content/Icons/rotate-check-24.svg";
import { Service } from "../../../Service";
import '../../../Content/stylesheet/navigation.css'
import "@bphxd/ds-core-react/lib/scss/bp-core.scss";
import { Navbar, NavbarBrand, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import { Avatar } from "@bphxd/ds-core-react";
import { Hamburger48, LogOut24, Remove48, Down16, Home24, ListChecked24 as ListAlt24, ChartUp24, Settings24, World24, Remove24, SwapUsers24, UserSettingsGear24, } from "@bphxd/ds-core-react/lib/icons";
import { useTranslation } from "react-i18next";
import LanguageSelectComponent from "../LanguageSelectComponent";
import { navLinkItemsIDs } from "../../../Constants";
import { useUser } from "../userContext";
import { useMyTrainingContext } from "../myTrainingContext";
import { useAssessmentContext } from "../assessmentContext";
import base from "../../../Services/BaseService";

const HeaderNew = (props) => {
  const { t, i18n } = useTranslation();
  const { activeNavLinkID, activeNavLinkRouteLowerCase } = props.activeNavLinkInfo || {};
  const bannerEnvName = process.env.NODE_ENV === "development" ? "Localhost" : process.env?.REACT_APP_BANNER_ENV;
  const navigate = useNavigate();
  const location = useLocation();
  const { updateMyTrainingCount, myTrainingCount } = useMyTrainingContext();
  const { updateAssessmentCount, assessmentCount } = useAssessmentContext();
  const { userData, languages, logOut, updateLanguagePreference } = useUser();
  const { userDisplayName, userID, userLevel, accessLevel, isTrainee, siteManager, fk_languageID: preferredLanguageID, isTraineeLocation } = userData;
  const isAssessmentAccessible = checkAssessmentAccess();
  const userRole = getUserRole();
  const userNameInitials = getUserNameInitials();
  const size = "lg";
  const leftAlignNav = false;
  const breakpoint = "lg";
  const [isOpenAvatarMenu, setIsOpenAvatarMenu] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(() => languages.find((language) => language.languageID === preferredLanguageID));

  const updateTraining = useRef();
  const updateAssessment = useRef();

  updateTraining.current = updateMyTrainingCount;
  updateAssessment.current = updateAssessmentCount;

  useEffect(() => {
    updateTraining.current();
    updateAssessment.current();
  }, [location]);

  function updateCurrentLanguage(selLang) {
    const { languageID } = selLang;

    i18n.changeLanguage(languageID);
    setCurrentLanguage(selLang);


    updateLanguagePreference(selLang);
    Service.sendMessage({ message: "Language changed" });

  }

  function getUserNameInitials() {
    const [firstName, lastName] = userDisplayName.split(" ");

    const firstNameInitials = firstName ? firstName.slice(0, 1) : "";
    const lastNameInitials = lastName ? lastName.slice(0, 1) : "";

    return firstNameInitials + lastNameInitials;
  }

  function checkAssessmentAccess() {
    if (
      (userLevel === "10" || userLevel === "15" || userLevel === "20") &&
      siteManager.toUpperCase() === "Y"
    ) {
      return true;
    }
    if (userLevel === "30" || userLevel === "40") {
      return true;
    }
    return false;
  }

  function getUserRole() {
    let Role = "";
    if (userLevel === "10") {
      Role = "System admin";
    } else if (userLevel === "15") {
      Role = "Global admin";
    } else if (userLevel === "20") {
      Role = "Global user";
    } else if (userLevel === "30") {
      if (accessLevel === "CTY") {
        Role = "Country manager";
      } else {
        Role = "PU manager";
      }
    } else if (userLevel === "40") {
      Role = "User";
    } else if (userLevel === "50") {
      Role = "Employee";
    }
    return Role;
  }

  function checkNavLinkDropdownAccess(dropdownItem) {
    if (
      dropdownItem === "assignEmployeesToModule" ||
      dropdownItem === "assignModuleToEmployees" ||
      dropdownItem === "curriculum" ||
      dropdownItem === "employees" ||
      dropdownItem === "question" ||
      dropdownItem === "region" ||
      dropdownItem === "users"
    ) {
      if (
        userLevel === "10" ||
        userLevel === "15" ||
        userLevel === "20" ||
        userLevel === "30" ||
        userLevel === "40"
      ) {
        return true;
      }
      return false;
    }
    if (
      dropdownItem === "threats"
    ) {
      if (
        userLevel === "10" ||
        userLevel === "15" ||
        userLevel === "20" ||
        userLevel === "30"
      ) {
        return true;
      }
      return false;
    }

    if (dropdownItem === "newsFeed") {
      if (userLevel === "10" || userLevel === "15") {
        return true;
      }
      return false;
    }

  }

  function RedirectToHomePage() {
    if (window.location.hash === "#/home") {
      navigate("/home1");
      Service.sendMessage({ message: "refresh home" });
    }
    navigate("/home");

    setIsOpenNav(false);
  }


  const toggleAvatarMenu = () => {
    if (!isOpenAvatarMenu) setIsOpenNav(false);
    setIsOpenAvatarMenu(!isOpenAvatarMenu);
  };

  const toggleNav = () => {
    if (!isOpenNav) setIsOpenAvatarMenu(false);
    setIsOpenNav(!isOpenNav);
  };

  const toggleUser = () => {
    base.add("user/toggleTenant/tenant", "").then(() => {
      window.location.reload()
    })
  }

  const toggleGlobal = () => {
    base.add("user/toggleTenant/global", "").then(() => {
      window.location.reload()
    })
  }

  const AvatarDropdownItems = () => (
    <>
      <DropdownItem header className="d-flex mb-3">
        <Avatar
          online
          initials={userNameInitials}
          href="#"
          className="me-4 align-self-start avatar-text"
        />
        <div>
          <div className="opacity-80 avatar-text">{userDisplayName}</div>
          <div className="opacity-60 avatar-text">{userID}</div>
          <div className="opacity-60 avatar-text">{`${userRole} (${userLevel})`}</div>
        </div>
      </DropdownItem>
      <>
        <DropdownItem divider />
        <DropdownItem
          className="justify-content-start"
          onClick={() => {
            setIsOpenLanguageModal(true);
            setIsOpenAvatarMenu(false);
          }
          }
        >
          <World24 className="dropdown-icon-prefix" />
          <span className="nav-text">{t("Common.Language Preferences")}</span>
        </DropdownItem>
        {isOpenLanguageModal && languages.length > 0 && (
          <LanguageSelectComponent
            closed={() => setIsOpenLanguageModal(false)}
            currentLanguage={currentLanguage}
            updateCurrentLanguage={updateCurrentLanguage}
            languages={languages}
          />
        )}
      </>
      {(userID === "shap8o" || userID === "whlqhm") &&
        <>
          <DropdownItem divider />
          <DropdownItem className="justify-content-start" onClick={toggleUser}>
            <SwapUsers24 className="dropdown-icon-prefix" />
            <span className="nav-text"> {`Set to ${userData.employeeTrainingLocation === "GASHCo" ? "M" : "G"}ASHCo`}</span>
          </DropdownItem>
        </>
      }
      {(userID === "shap8o" || userID === "whlqhm") &&
        <DropdownItem className="justify-content-start" onClick={toggleGlobal}>
          <UserSettingsGear24 className="dropdown-icon-prefix" />
          <span className="nav-text"> {`Set to Global`}</span>
        </DropdownItem>
      }
      <DropdownItem divider />
      <DropdownItem className="justify-content-start" onClick={logOut}>
        <LogOut24 className="dropdown-icon-prefix" />
        <span className="nav-text"> {t("Common.Log Out")}</span>
      </DropdownItem>
    </>
  );

  function isDropdownNavlinkActive(currentDropdownItemRoute) {
    const currentDropdownItemRouteLowerCase =
      currentDropdownItemRoute.toLowerCase();

    if (currentDropdownItemRouteLowerCase === activeNavLinkRouteLowerCase)
      return true;
    return false;
  }

  return (
    <>
      {isBannerVisible &&
        (bannerEnvName === "Localhost" ||
          bannerEnvName === "Development" ||
          bannerEnvName === "Pre-Production") && (
          <header>
            <div className="nav-banner-header">
              Non-Production Environment: {bannerEnvName}
              <span className="nav-text"
                onClick={() => setIsBannerVisible(false)}
                style={{ marginLeft: "16px", cursor: "pointer" }}
              >
                <Remove24 />
              </span>
            </div>
          </header>
        )}

      <div id="nav-wrapper">

        <Navbar
          id="main-navbar-containerID"
          expand={breakpoint}
          className={`${size ? `navbar-${size}` : ""} ${isOpenAvatarMenu || isOpenNav ? "align-items-start pt-4 pb-4" : "pt-4 pb-4"
            }`}
        >

          <NavbarBrand>
            <img className="mt-1" alt="CADOPS Logo" src={Cadopslogo}></img>
          </NavbarBrand>

          {/* mobile Avatar Collapse menu toggler, hidden at large breakpoint and up */}
          {isOpenAvatarMenu ? (
            <button
              onClick={toggleAvatarMenu}
              className={`btn-close-inline d-${breakpoint}-none ms-auto me-3`}
            >
              <Remove48 />
            </button>
          ) : (
            <Avatar
              size="sm"
              initials={userNameInitials}
              onClick={toggleAvatarMenu}
              className={`d-${breakpoint}-none ms-auto me-4 avatar-text`}
            />
          )}

          <button
            onClick={toggleNav}
            className={`btn-close-inline d-${breakpoint}-none`}
          >
            {isOpenNav ? <Remove48 /> : <Hamburger48 />}
          </button>

          {/* mobile Avatar Collapse menu, hidden at large breakpoint and up */}
          <Collapse
            isOpen={isOpenAvatarMenu}
            navbar
            className={`${leftAlignNav ? "flex-grow-0" : ""}`}
          >
            <Nav navbar className={`d-${breakpoint}-none`}>
              <DropdownMenu
                className={`${size ? `dropdown-menu-${size}` : ""
                  } bg-transparent position-static d-block mt-3 border-top pt-4`}
              >
                <AvatarDropdownItems />
              </DropdownMenu>
            </Nav>
          </Collapse>

          <Collapse isOpen={isOpenNav} navbar>
            <Nav navbar>
              {userLevel !== "50" && (
                <NavItem>
                  <NavLink
                    href="#"
                    id={navLinkItemsIDs.home}
                    active={activeNavLinkID === navLinkItemsIDs.home}
                    onClick={RedirectToHomePage}

                  >
                    <Home24 />
                    <span className="nav-text" >{t("Common.Home")}</span>
                  </NavLink>
                </NavItem>
              )}
              {((userLevel === "50" && isTraineeLocation === 1) || (isTrainee === 1 && isTraineeLocation === 1)) && (
                <NavItem>
                  <NavLink
                    href="#"
                    id={navLinkItemsIDs.myTraining}
                    active={activeNavLinkID === navLinkItemsIDs.myTraining}
                    className="position-relative"
                    onClick={() => {
                      navigate("/MyTraining");
                      setIsOpenNav(false);
                    }}
                  >
                    <MyTrainingIcon />

                    <span className="nav-text">{t("Common.MyTraining")}</span>
                    {myTrainingCount > 0 && (
                      <div className="navlink-notification-count mytraining-count">
                        <div>
                          {myTrainingCount > 9 ? "9+" : myTrainingCount}
                        </div>
                      </div>
                    )}
                  </NavLink>
                </NavItem>
              )}
              {userLevel !== "50" && (
                <>
                  {isAssessmentAccessible && (
                    <NavItem>
                      <NavLink
                        href="#"
                        id={navLinkItemsIDs.assessment}
                        active={activeNavLinkID === navLinkItemsIDs.assessment}
                        className="position-relative"
                        onClick={() => {
                          navigate("/ManageAssessment");
                          setIsOpenNav(false);
                        }}
                      >
                        <ListAlt24 />
                        <span className="nav-text">{t("Common.Assessment")}</span>
                        {assessmentCount > 0 && (
                          <div className="navlink-notification-count assessment-count">
                            <div>
                              {assessmentCount > 9
                                ? "9+"
                                : assessmentCount}
                            </div>
                          </div>
                        )}
                      </NavLink>
                    </NavItem>
                  )}
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      caret

                    >
                      <ChartUp24 className="no-transform" />
                      <span className="nav-text">{t("Common.Reports")}</span>
                      <Down16 />
                    </DropdownToggle>
                    <DropdownMenu
                      className={`nav-link-dropdown-hover ${size ? `dropdown-menu-${size}` : ""
                        }`}
                    >
                      <DropdownItem
                        active={
                          isDropdownNavlinkActive("/compliancereport")
                            ? true
                            : false
                        }
                        onClick={() => {
                          navigate("/compliancereport");
                          setIsOpenNav(false);
                        }}
                      >
                        <span className="nav-text">{t("Common.Compliance Report")}</span>
                      </DropdownItem>

                      <DropdownItem
                        active={
                          isDropdownNavlinkActive("/evaluationreport")
                            ? true
                            : false
                        }
                        onClick={() => {
                          navigate("/evaluationreport");
                          setIsOpenNav(false);
                        }}
                      >
                        <span className="nav-text">{t("Common.Evaluation Report")}</span>
                      </DropdownItem>

                      <DropdownItem
                        active={
                          isDropdownNavlinkActive("/preformatteddocument")
                            ? true
                            : false
                        }
                        onClick={() => {
                          navigate("/PreformattedDocument");
                          setIsOpenNav(false);
                        }}
                      >
                        <span className="nav-text">{t("Common.Preformatted Document")}</span>
                      </DropdownItem>

                      <DropdownItem
                        active={
                          isDropdownNavlinkActive("/myfavouritereports")
                            ? true
                            : false
                        }
                        onClick={() => {
                          navigate("/MyFavouriteReports");
                          setIsOpenNav(false);
                        }}
                      >
                        <span className="nav-text">{t("Common.My Favourite Reports")}</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      caret

                    >
                      <Settings24 />
                      <span className="nav-text">{t("Common.Manage Data")}</span>
                      <Down16 />
                    </DropdownToggle>
                    <DropdownMenu
                      className={`nav-link-dropdown-hover ${size ? `dropdown-menu-${size}` : ""
                        }`}
                    >
                      {checkNavLinkDropdownAccess("assignEmployeesToModule") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/assignemployeestomodule")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/AssignEmployeesToModule");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Assign Employees to Module")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                      {checkNavLinkDropdownAccess("assignModuleToEmployees") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/assignmoduletoemployee")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/assignmoduletoemployee");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Assign Modules to Employee")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                      {checkNavLinkDropdownAccess("curriculum") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/managecurriculum")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/ManageCurriculum");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Curriculum")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                      {checkNavLinkDropdownAccess("employees") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/manageemployees")
                              ? true
                              : false
                          }
                          style={{ display: "none" }}
                          onClick={() => {
                            navigate("/ManageEmployees");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Employees")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}

                      {checkNavLinkDropdownAccess("newsFeed") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/managenewsfeed")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/ManageNewsFeed");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.News Feed")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}

                      {checkNavLinkDropdownAccess("question") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/managequestions")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/ManageQuestions");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Question")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}

                      {checkNavLinkDropdownAccess("region") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/manageregion")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/ManageRegion");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Region")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                      {checkNavLinkDropdownAccess("threats") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/managethreats")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/ManageThreats");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Threats")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                      {checkNavLinkDropdownAccess("users") ? (
                        <DropdownItem
                          active={
                            isDropdownNavlinkActive("/managesystemusers")
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/Managesystemusers");
                            setIsOpenNav(false);
                          }}
                        >
                          <span className="nav-text">{t("Common.Users")}</span>
                        </DropdownItem>
                      ) : (
                        ""
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
            </Nav>
          </Collapse>

          {/* Avatar Dropdown for large breakpoint and up, hidden below large breakpoint */}
          <UncontrolledDropdown
            className={`d-none d-${breakpoint}-inline-flex ms-auto`}
          >
            {/* <Avatar
              size={size === "lg" ? undefined : "sm"}
              initials={userNameInitials}
              dropdown
            /> */}
            <div className="avatar-text">
              <DropdownToggle caret nav className="avatar avatar-action ">
                <Avatar
                  size={size === "lg" ? undefined : "sm"}
                  initials={userNameInitials}
                  style={{ fontSize: "16px", fontFamily: "Roboto" }}
                  className=""
                />
                <Down16 className="avatar-arrow" />
              </DropdownToggle>
            </div>
            <DropdownMenu
              end
              className={`nav-link-dropdown-hover ${size ? `dropdown-menu-${size}` : ""
                }`}
            >
              <AvatarDropdownItems />
            </DropdownMenu>
          </UncontrolledDropdown>
        </Navbar>
      </div>
    </>
  );
};

export default withRouter(HeaderNew);
