import React, { useEffect, useState } from "react";
import { Toast } from '@bphxd/ds-core-react';
import { Alert24 } from "@bphxd/ds-core-react/lib/icons";

const ErrorToastNew = (props) => {

    const [show1, setShow1] = useState(props.show);

    useEffect(() => {
        setShow1(props.show);
    }, [props.show]);

    useEffect(() => {
        if (show1) {
            const timer = setTimeout(() => {
                setShow1(false)
                if (props.setHasFailed && typeof props.setHasFailed === 'function') {
                    props.setHasFailed(false);
                }
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [show1, props]);

    return (
        <>
            <div className={`${props.form ? "mt-n4" : "mt-4"} toast-container top-0 start-50 translate-middle-x`} >
                <Toast
                    light
                    border
                    className="toast-width"
                    isOpen={show1}
                >
                    <Toast.Body>
                        <span className="me-auto toast-text">
                            <Alert24 />
                            {props.Message}
                        </span>
                    </Toast.Body>
                </Toast>
            </div>

        </>
    );
};

export default ErrorToastNew;
