import React, { useState, useEffect } from 'react';
import { Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { Down32, Remove48, Up32 } from '@bphxd/ds-core-react/lib/icons';
import _ from 'lodash';

const AssessmentPart2 = (props) => {
    const { setFormChanged, setSaveDisbaled, employeeData, assessment_Type, isDATPart2, assessment, invalidActivity, invalidConfirm, offlineDAT2, stepData,
        setInvalidActivity, setInvalidConfirm, clearCalled, onUpdate } = props
    const [hidePart2, setHidePart2] = useState(false);
    const [selectedCells, setSelectedCells] = useState([]);
    const [assessmentCopy, setAssessmentCopy] = useState(assessment);

    useEffect(() => {
        setSelectedCells([]);
        setAssessmentCopy(assessment);
    }, [clearCalled, assessment]);

    useEffect(() => {
        const updatedAssessment = assessmentCopy;
        onUpdate(updatedAssessment);
    }, [onUpdate, assessmentCopy]);

    const handleCheckboxClick = (key) => {
        setFormChanged(true);
        setSaveDisbaled(false);
        const keyArray = key.split('_');
        const updatedAssessment = _.cloneDeep(assessmentCopy);
        const employeeIndex = updatedAssessment.results.findIndex((employee) => employee.fk_EmployeeID === keyArray[0]);
        if (isDATPart2) {
            const stepKey = `${keyArray[0]}_${keyArray[1]}`
            const isInvalid = invalidActivity.includes(stepKey);
            if (isInvalid) {
                setInvalidActivity((prev) => {
                    return prev.filter((d) => d !== stepKey);
                });
            }
            const stepIndex = updatedAssessment.results[employeeIndex].assessmentResults.activities.findIndex(
                (step) => step.stepID === keyArray[1]
            );
            const isChecked = document.getElementById(key).checked;
            let newResponse = "";

            if (isChecked) {
                newResponse = keyArray[2];
            }
            if (employeeIndex !== -1 && stepIndex !== -1) {
                const assessmentResultsCopy = JSON.parse(JSON.stringify(updatedAssessment.results[employeeIndex].assessmentResults));
                assessmentResultsCopy.activities[stepIndex].employeeResult = newResponse;
                updatedAssessment.results[employeeIndex].assessmentResults = assessmentResultsCopy;
            }
        }
        setAssessmentCopy(updatedAssessment);
    };

    const handleRemoveClick = (key) => {
        setFormChanged(true);
        setSaveDisbaled(false);
        let removeStep = false;
        if (selectedCells.includes(key)) {
            removeStep = true;
            setSelectedCells((prevSelectedCells) => {
                return prevSelectedCells.filter((cellKey) => cellKey !== key);
            });
        } else {
            setSelectedCells((prevSelectedCells) => {
                return [...prevSelectedCells, key];
            });
        }
        const updatedAssessment = _.cloneDeep(assessmentCopy);
        const keyArray = key.split('_')
        const employeeIndex = updatedAssessment.results.findIndex((employee) => employee.fk_EmployeeID === keyArray[0]);
        if (!removeStep) {
            const assessmentResultsCopy = JSON.parse(JSON.stringify(updatedAssessment.results[employeeIndex].assessmentResults));
            assessmentResultsCopy.activities.push({ "stepID": keyArray[1] })
            updatedAssessment.results[employeeIndex].assessmentResults = assessmentResultsCopy;

        } else {
            const filteredActivities = updatedAssessment.results[employeeIndex].assessmentResults.activities.filter(item => item.stepID !== keyArray[1]);
            updatedAssessment.results[employeeIndex].assessmentResults.activities = filteredActivities;
        }
        setAssessmentCopy(updatedAssessment);
    };

    useEffect(() => {
        setAssessmentCopy(assessment);
    }, [clearCalled, assessment]);


    const handleConfirmClick = (key) => {
        setFormChanged(true);
        setSaveDisbaled(false);
        const itemChecked = document.getElementById(key).checked;
        const isInvalid = invalidConfirm.includes(key);
        if (isInvalid) {
            setInvalidConfirm((prev) => {
                return prev.filter((d) => d !== key);
            });
        }
        const updatedAssessment = _.cloneDeep(assessmentCopy);
        // keyArray[0] = employeeID
        const keyArray = key.split('_')
        const employeeIndex = updatedAssessment.results.findIndex(
            (employee) => employee.fk_EmployeeID === keyArray[0]
        );
        updatedAssessment.results[employeeIndex].confirmEntries = itemChecked;
        setAssessmentCopy(updatedAssessment);
    };

    return (
        <>
            {(assessment_Type === "TAR" || isDATPart2) && (
                <>
                    <tr className="custom-heading-height mt-3" >
                        <td className="text-start col-heading  p-2" onClick={() => setHidePart2(!hidePart2)} style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white', paddingRight: '17px' }}>
                            <Row className="header-content">
                                <Col xs="9">
                                    {isDATPart2 ? "Driver training part 2" : "Part 2 - Task observation"}

                                </Col>
                                <Col xs="3" className="text-end">
                                    {!hidePart2 ?
                                        <Up32 />
                                        :
                                        <Down32 />
                                    }
                                </Col>
                            </Row>

                        </td>
                        <td colSpan={employeeData.length + 1} className="colspan-border p-2" >

                        </td>
                    </tr>
                    {stepData.map((rowData, rowIndex) => {
                        return (
                            <React.Fragment key={`rf_${rowIndex}`} >
                                {!hidePart2 ?
                                    <tr key={rowIndex} className="custom-row-height">
                                        <td className={rowData.step_Level === "1" ? "cell-grey border-question-row text-start p-2" : "border-question-row text-start p-2"} style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: rowData.step_Level === "1" ? "#faf6f6" : "white", paddingRight: '17px' }}>
                                            <div className="d-flex" style={{ fontSize: "14px" }}>
                                                <div style={{ flex: "0 0 50px" }} className={rowData.step_Critical === "Y" ? "critical-step" : ""}>
                                                    {rowData.step_Label}
                                                </div>
                                                <div style={{ flex: "0 0 1", }} className={rowData.step_Critical === "Y" ? "critical-step cell-content" : " cell-content"}>
                                                    {rowData.step_Desc}
                                                </div>
                                            </div>
                                        </td>
                                        {employeeData.map((employee, index) => {
                                            const part2Key = `${employee.fk_EmployeeID}_${rowData.fk_ID}`;

                                            if (isDATPart2) {
                                                let employeeInfo = assessmentCopy.results.filter(d => d.fk_EmployeeID === employee.fk_EmployeeID);
                                                let stepInfo = employeeInfo[0].assessmentResults.activities.filter(d => d.stepID === rowData.fk_ID);
                                                let checkboxes = []
                                                for (let i = 0; i < offlineDAT2.length; i++) {
                                                    const labelValue = offlineDAT2[i];
                                                    if (rowData.step_Level !== "1") {
                                                        checkboxes.push(

                                                            <div key={`${part2Key}_${labelValue}`} className="d-inline-block pe-2">
                                                                <div className="text-center w-100">
                                                                    <Label className="ms-3 pe-3 option-label" for={`${part2Key}_${labelValue}`}>
                                                                        {labelValue}
                                                                    </Label>
                                                                </div>
                                                                <div>
                                                                    <Input className="mt-n1" id={`${part2Key}_${labelValue}`} key={`${part2Key}_${labelValue}`} name={`${employee.fk_EmployeeID}_${rowData.fk_ID}`} type="radio"
                                                                        onChange={() => handleCheckboxClick(`${part2Key}_${labelValue}`)}
                                                                        invalid={invalidActivity.includes(`${employee.fk_EmployeeID}_${rowData.fk_ID}`)}
                                                                        checked={stepInfo[0].employeeResult.split(';').includes(`${labelValue}`)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                }
                                                return (
                                                    <td key={part2Key} className="border-question p-2" style={{ backgroundColor: rowData.step_Level === "1" ? "#faf6f6" : "white" }} >
                                                        <FormGroup>
                                                            {checkboxes}
                                                        </FormGroup>
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td
                                                        key={part2Key}
                                                        className="border-question p-2"
                                                        style={{ backgroundColor: rowData.step_Level === "1" ? "#faf6f6" : "white" }}
                                                        onClick={() => rowData.step_Level === "1" ? "" : handleRemoveClick(part2Key)}
                                                    >
                                                        {selectedCells.includes(part2Key) && (
                                                            <Remove48 />
                                                        )}
                                                    </td>
                                                );
                                            }
                                        })}
                                    </tr>
                                    :
                                    (null)
                                }
                                {rowIndex === stepData.length - 1 && !isDATPart2 ? (
                                    <tr key={rowIndex + 1} className="custom-heading-height">
                                        <td className="border-question-row border-bottom text-end col-subheading  p-2" style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white', paddingRight: '17px' }}>
                                            Confirm entries?
                                        </td>
                                        {employeeData.map((rowData, employeeIndex) => (
                                            <td key={employeeIndex} className="border-question border-bottom  p-2" >
                                                <div className="d-inline-block pe-2">
                                                    <div>
                                                        <Input className="mt-n1"
                                                            key={`${rowData.fk_EmployeeID}_confirm`}
                                                            id={`${rowData.fk_EmployeeID}_confirm`}
                                                            invalid={invalidConfirm.includes(`${rowData.fk_EmployeeID}_confirm`)}
                                                            type="checkbox"
                                                            onChange={() => handleConfirmClick(`${rowData.fk_EmployeeID}_confirm`)}
                                                            checked={assessmentCopy.results.find(option => option.fk_EmployeeID === rowData.fk_EmployeeID)?.confirmEntries}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ) : (
                                    <tr key={rowIndex + 1} >

                                    </tr>
                                )
                                }
                            </React.Fragment>

                        )
                    }

                    )}
                </>
            )
            }

        </>
    );
}

export default AssessmentPart2;
