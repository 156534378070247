import React from 'react';
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import base from "../../../../Services/BaseService";
import { Row, Col, PopoverBody, UncontrolledPopover } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { useModuleContext } from "../../../Shared/moduleContext";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';
import { useUser } from "../../../Shared/userContext";
import ErrorToastNew from "../../../Toast/ErrorToastNew1";

import { World24, Info24 } from '@bphxd/ds-core-react/lib/icons';

const StepModal = ({ closeStepModal, stepData, saveStepModal, create, selectedHazards, stepGridData }) => {
    const { languages } = useUser();
    const { moduleData, moduleLanguagePreference, globalUser } = useModuleContext();
    const { t } = useTranslation();
    const [localStepData, setLocalStepData] = useState(stepData);
    const parent_StepID = localStepData[0].parent_stepID;
    const modalTitle = create ? t("Curriculumpage.Add Step / Activity") : t("Curriculumpage.Edit Step / Activity")
    const addAboveBelowOptions = [{ "value": "B", "label": t("Curriculumpage.Above") }, { "value": "A", "label": t("Curriculumpage.Below") }];
    const [aboveBelowSelected, setAboveBelowSelected] = useState("");
    const [stepLabel, setStepLabel] = useState(localStepData[0].step_Label);
    const [levelData, setLevelData] = useState([]);
    const [criticalStepsData, setCriticalStepsData] = useState([]);
    const [hazardsData, setHazardsData] = useState([]);
    const [stepHazardIDsSelected, setStepHazardIDsSelected] = useState(selectedHazards);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [invalid, setInvalid] = useState([])
    const [language, setLanguage] = useState(moduleLanguagePreference)
    const [disabled, setDisabled] = useState([])
    const [showToast, setShowToast] = useState({ Name: "", show: false });
    var stepLevelRef = useRef(localStepData[0].step_Level)

    useEffect(() => {
        setLanguage(moduleLanguagePreference);
    }, [moduleLanguagePreference]);

    useEffect(() => {
        let disabledFields = []
        if (create === false && moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type === "TSK" && language === "en-GB") {
            disabledFields.push("step_Level", "Step_Description", "stHazards", "Step_Criticalddl")
        }
        if (create === false && globalUser !== "GADMIN" && moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type !== "TSK" && language === "en-GB") {
            disabledFields.push("step_Level", "Step_Description", "stHazards", "Step_Criticalddl")
        }
        if (create === false && moduleData[0].task_Site === "GLOBAL" && language !== "en-GB") {
            disabledFields.push("step_Level", "stHazards", "Step_Criticalddl")
        }
        if (stepData[0].step_Level === "1") {
            disabledFields.push("Step_Criticalddl")
        }
        setDisabled(disabledFields)

    }, [globalUser, moduleData, language, stepData, create]);

    const handleUPDownClick = (params) => {
        setAboveBelowSelected(params);
        getLevelData(localStepData[0], params, false);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
        }),
    };

    const handleLanguageChange = (event) => {
        let selectedLanguage = event.target.value
        setLanguage(selectedLanguage);
    };

    useEffect(() => {
        setStepLabel(localStepData[0].step_Label)
        if (create === false) {
            getLevelData(localStepData[0], "NA", true);
        }
        base.getAll("drop/frm/YORN").then((res) => {
            if (res != null && res !== undefined) {
                var yesNoVal = [];
                for (let i = 0; i < res.length; i++) {
                    var item = {};
                    item.value = res[i].pValue1;
                    item.label = res[i].pValue2;
                    yesNoVal.push(item);
                }
                setCriticalStepsData(yesNoVal);
            }
            base.getAll("hazard/Qry").then((res) => {
                if (res != null && res !== undefined) {
                    var level = [];
                    for (let i = 0; i < res.length; i++) {
                        var item = {};
                        if (res[i].hazard_Description != null) {
                            item = {};
                            item.value = res[i].hazardID;
                            item.label = res[i].hazard_Description;
                            level.push(item);
                        }
                    }
                    setHazardsData(level);
                }
            });

        });
        // eslint-disable-next-line
    }, []);

    const getLevelData = (levelClicked, upDown, isEdit) => {
        var url = "";
        if (isEdit) {
            url = "drop/frm1/SLEVEL/" + levelClicked.stepID + "/" + upDown + "/" + levelClicked.step_Level + "/" + isEdit;
        } else {
            url = "drop/frm1/SLEVEL/" + levelClicked.parent_stepID + "/" + upDown + "/" + levelClicked.parent_step_Level + "/" + isEdit;
        }
        base.getAll(url).then((res) => {
            if (res != null && res !== undefined) {
                var level = [];
                for (let i = 0; i < res.length; i++) {
                    var item = {};
                    item.value = res[i].pValue1;
                    item.label = res[i].pValue1 + " " + res[i].pValue2;
                    level.push(item);
                }
                setLevelData(level);
            }
        });

    };

    const handleChange = (id, value, condition) => {
        setInvalid(prevInvalid =>
            prevInvalid.filter(element => element !== id)
        );
        switch (condition) {
            case 1:
                setLocalStepData(prevLocalStepData => {
                    const updatedStepData = prevLocalStepData.map(step => {
                        const updatedStep = {
                            ...step,
                            [id]: value
                        };
                        if (id === "step_Level" && value === "1") {
                            updatedStep.step_Critical = "N";
                        }
                        return updatedStep;
                    });
                    return updatedStepData;
                });
                break;
            case 2:
                setLocalStepData((prevLocalStepData) => {
                    const updatedLanguageID = language;
                    const updatedItemIndex = prevLocalStepData.findIndex(item => item.fk_LanguageID === updatedLanguageID);
                    if (updatedItemIndex !== -1) {
                        const updatedItem = {
                            ...prevLocalStepData[updatedItemIndex],
                            [id]: value
                        };
                        const updatedStepData = [
                            ...prevLocalStepData.slice(0, updatedItemIndex),
                            updatedItem,
                            ...prevLocalStepData.slice(updatedItemIndex + 1)
                        ];
                        return updatedStepData;
                    } else {
                        return prevLocalStepData;
                    }
                });
                break;
            default:
        }
    }

    function DataSavingToastonClick() {
        setShowToast({ Name: "", show: false });
    }
    const handleChangeHazard = (selected) => {
        setInvalid(prevInvalid =>
            prevInvalid.filter(element => element !== "stHazards")
        );
        var hazards = [];
        for (let i = 0; i < selected.length; i++) {
            hazards.push(selected[i].value);
        }
        setStepHazardIDsSelected(hazards);
    };

    const SaveStep = () => {
        let hazardKeyValues = []
        if (localStepData[0].step_Critical === "Y") {
            hazardKeyValues = stepHazardIDsSelected.map(hazard => ({
                "fk_hazardID": hazard
            }));
        }

        const saveStepData = localStepData.map(step => ({
            ...step,
            "step_Description": step.step_Description.trim(),
            "threats": hazardKeyValues
        }));

        let fail = []
        let steps = stepGridData.filter(f => f.step_Level === "1")
        if (create === true) {
            if (steps.length >= 8) {
                fail.push("maxStepsReached")
            }
        } else {
            if (stepLevelRef.current === "2") {
                if (steps.length >= 8) {
                    fail.push("maxStepsReached")
                }
            } else if (stepLevelRef.current === "1") {
                if (steps.length > 8) {
                    fail.push("maxStepsReached")
                }
            }
        }

        if (create) {
            if (aboveBelowSelected === "") {
                fail.push("upDown")
            }

            let stepLabel = localStepData[0].step_Label.substring(0, 2)
            let numberOfActivities = stepGridData.filter(f => f.step_Label.substring(0, 2) === stepLabel)
            let activities = numberOfActivities.filter(f => f.step_Level !== "1")

            if (localStepData[0].step_Level !== "1") {
                if (activities.length >= 22) {
                    fail.push("maxActivitiesReached")
                }
            }
        }

        saveStepData.forEach((item) => {
            if (item.step_Description === "") {
                fail.push("step_Description");
            }
            if (item.step_Critical === "Y" && stepHazardIDsSelected.length === 0) {
                fail.push("stHazards");
            }
            if (item.step_Level === "") {
                fail.push("step_Level");
            }
        });

        if (fail.length !== 0) {
            setSaveDisabled(false);
            setInvalid(fail);

            if (fail.includes("maxStepsReached")) {
                setShowToast({ Name: "cantAddStep", show: true });
                document.addEventListener("mousedown", DataSavingToastonClick);
            }
            if (fail.includes("maxActivitiesReached")) {
                setShowToast({ Name: "cantAddActivity", show: true });
                document.addEventListener("mousedown", DataSavingToastonClick);
            }
        } else {
            let promise = [];
            if (create) {
                promise.push(base.add(process.env.REACT_APP_STEP_ADD + "/" + parent_StepID + "/" + aboveBelowSelected, saveStepData[0]))
            } else {
                promise.push(base.update(process.env.REACT_APP_STEP_UPD, saveStepData))
            }
            Promise.all(promise)
                .then(([res]) => {
                    saveStepModal();
                }).catch((error) => {
                    setSaveDisabled(false);
                });
        }
    };
    const [hasFailed, setHasFailed] = useState("")

    return (
        <div className="bd-example-modal">
            <div >
                <Modal centered className="modal-70 modal-dialog-centered" isOpen={true} >
                    <Modal.Header
                        className="modalPopupBGColour"
                        onToggleOpen={closeStepModal}
                    >
                        {showToast.Name === "cantAddStep" && (
                            <ErrorToastNew
                                show={showToast.show}
                                Message={t("Curriculumpage.ErrorMorethanEightSteps")}
                                setHasFailed={setHasFailed}
                                hasFailed={hasFailed}
                                form={true}
                            />
                        )}
                        {showToast.Name === "cantAddActivity" && (

                            <ErrorToastNew
                                show={showToast.show}
                                Message={t("Curriculumpage.ErrorMoreThan22ItemstoStep")}
                                setHasFailed={setHasFailed}
                                hasFailed={hasFailed}
                                form={true}

                            />

                        )}
                        <div className="">
                            <div className="modalHeaderGlobal ms-5"
                                style={{ marginLeft: "2%" }}
                            >
                                {modalTitle}
                                {stepLabel}
                            </div>
                        </div>

                    </Modal.Header>

                    <Modal.Body>
                        {create === true &&
                            <Row>
                                <Col lg="6">
                                    <Label>
                                        {t("Curriculumpage.Add_Above_/_Below")}
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="upDown"
                                        name="upDown"
                                        placeholder={""}
                                        value={addAboveBelowOptions.find((option) => option.value === aboveBelowSelected)}
                                        options={addAboveBelowOptions}
                                        styles={customStyles}
                                        onChange={(e) => {
                                            handleUPDownClick(e.value);
                                            setInvalid(prevInvalid =>
                                                prevInvalid.filter(element => element !== "upDown")
                                            );
                                        }}
                                        invalid={invalid.includes(`upDown`)}
                                        classNamePrefix="react-select"
                                    ></Select>
                                    <Col >
                                        <label className="float-start text-danger h7"
                                            id="addup"
                                            style={{
                                                display: "none",
                                            }}>
                                            {t("ErrorMessages.Required")}
                                        </label>
                                    </Col>
                                </Col>
                            </Row>
                        }
                        <Row className="mt-3">
                            <Col lg="6">
                                <Label>
                                    {t("Curriculumpage.Level")}
                                    <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    id="step_Level"
                                    name="Step_Level"
                                    placeholder={""}
                                    value={levelData.find((option) => option.value === localStepData[0].step_Level)}
                                    options={levelData}
                                    invalid={invalid.includes(`step_Level`)}
                                    styles={customStyles}
                                    isDisabled={disabled.includes("step_Level")}
                                    onChange={(e) => {
                                        handleChange("step_Level", e.value, 1);
                                        setInvalid(prevInvalid =>
                                            prevInvalid.filter(element => element !== "step_Level")
                                        );
                                        if (e.value === "1") {
                                            setDisabled(prevDisabled => {
                                                const newDisabled = [...prevDisabled];
                                                newDisabled.push("Step_Criticalddl");
                                                return newDisabled;
                                            });
                                        } else {
                                            setDisabled(prevInvalid =>
                                                prevInvalid.filter(element => element !== "Step_Criticalddl")
                                            );
                                        }
                                    }
                                    }
                                    classNamePrefix="react-select"
                                >
                                </Select>
                            </Col>
                            {create === false &&
                                <>
                                    <Col lg="6">
                                        <Row>
                                            <Col lg="12">
                                                <Label>
                                                    {t("Curriculumpage.Label")}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <div
                                                    className="mt-3"
                                                    id="Step_Label"
                                                    name="Step_Label"
                                                    value={stepLabel}
                                                >
                                                    <span>{stepLabel}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row className="mt-3">
                            <Col lg="12">
                                <Row>
                                    <Col>
                                        <Label>
                                            {t("Curriculumpage.Description")}
                                            <span className="text-danger">*</span>
                                        </Label>
                                    </Col>
                                    {(moduleData[0].task_Site === "GLOBAL" && language !== "en-GB") && (
                                        <Col className="text-end">
                                            <span>
                                                <Info24
                                                    id='questionPopover'
                                                ></Info24>
                                                <UncontrolledPopover
                                                    placement="top"
                                                    target="questionPopover"
                                                    trigger="hover focus"
                                                    popperClassName="popover"
                                                >
                                                    <PopoverBody className="tooltip-msg">
                                                        {localStepData.find(item => item.fk_LanguageID === "en-GB")?.step_Description}
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </span>
                                        </Col>
                                    )
                                    }
                                </Row>
                                <Input
                                    type="textarea"
                                    id="Step_Description"
                                    name="Step_Description"
                                    rows="2"
                                    disabled={disabled.includes("Step_Description")}
                                    maxLength={255}
                                    value={localStepData.find(item => item.fk_LanguageID === language)?.step_Description}
                                    invalid={invalid.includes(`step_Description`)}
                                    onChange={(e) => handleChange("step_Description", e.target.value, 2)}
                                ></Input>
                            </Col>
                            <Col lg="12">
                                <p className="text-end desclength">
                                    {localStepData.find(item => item.fk_LanguageID === language)?.step_Description.length}/255
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg="2" className=" ">
                                <Label>
                                    {t("Curriculumpage.Critical")}
                                    <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    id="Step_Criticalddl"
                                    name="Step_Criticalddl"
                                    placeholder={""}
                                    value={criticalStepsData.find((option) => option.value === localStepData[0].step_Critical)}
                                    options={criticalStepsData}
                                    isDisabled={disabled.includes("Step_Criticalddl")}
                                    onChange={(e) => {
                                        handleChange("step_Critical", e.value, 1);
                                    }}
                                    classNamePrefix="react-select"
                                >
                                </Select>
                            </Col>
                            {localStepData[0].step_Level !== "1" &&
                                localStepData[0].step_Critical === "Y" && (
                                    <Col xs={12} md={10}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>
                                                    {t("Curriculumpage.Add_Threat")}
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <Select
                                                    id="stHazards"
                                                    options={hazardsData}
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    classNamePrefix="react-select"
                                                    invalid={invalid.includes(`stHazards`)}
                                                    styles={customStyles}
                                                    isDisabled={disabled.includes("stHazards")}
                                                    value={hazardsData.filter(option => stepHazardIDsSelected.includes(option.value))}
                                                    onChange={handleChangeHazard}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <label className="float-start text-danger h7 add-threat-err-msg"
                                                    id="addthreat"
                                                    style={{
                                                        display: "none",
                                                    }}>
                                                    {t("ErrorMessages.Required")}
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className="w-100">
                            <Col xs="4" className="text-start ">
                                {create === false ?
                                    <div className="d-flex" >
                                        <World24 className="icon-margin" />
                                        <Input type="select" className="border-0 " value={language} onChange={handleLanguageChange}>
                                            {languages.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.languageID} >
                                                        {item.language_Name}
                                                    </option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </div>
                                    : ""
                                }
                            </Col>
                            <Col xs="8" className="text-end">
                                <Button className="rounded-pill btn-style me-3" size="sm" color="standard-secondary" onClick={closeStepModal} >
                                    <Trans i18nKey="Common.Cancel">Cancel</Trans>
                                </Button>
                                <Button className="rounded-pill btn-style" size="sm" color="standard-primary" disabled={saveDisabled}
                                    onClick={() => {
                                        setSaveDisabled(true);
                                        SaveStep();
                                    }}>
                                    <Trans i18nKey="Common.Save">Save</Trans>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div></div >
    );
}

export default StepModal;
