import { Modal, ModalFooter, Button, ModalBody } from "reactstrap";

const ConfirmCancel = (props) => {

  const handleChange = (e) => {
    props.close();
  };

  const handleClose = () => {
    props.handleYes();
  }

  return (

    <Modal
      id="cancel"
      size="md"
      centered
      isOpen={props.show}
      fade={false}
      backdrop={true}
      wrapClassName="cancel"
    >
      <ModalBody
        className="text-center"
        style={{ borderBottom: "1px solid #68686740" }}
      >
        <div className="popup-text">
          Are you sure you want to cancel?
        </div>
      </ModalBody>
      <ModalFooter style={{ alignSelf: "center", borderTop: "none" }} >
        <Button
          color="standard-secondary"
          className="rounded-pill btn-style"
          size="sm"

          onClick={handleChange}
        >
          No
        </Button>{" "}
        <Button
          className="rounded-pill btn-style"
          color="standard-primary"
          size="sm"
          onClick={handleClose}
        >
          Yes
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmCancel;
