import React, { useRef } from 'react';
import { Button, Input } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import base from "../../../../Services/BaseService";
import "ag-grid-community";
import "ag-grid-enterprise";
import "../../../../Content/stylesheet/ag-grid-style.css";
import { useModuleContext } from "../../../Shared/moduleContext";
import CustomCheckboxFilter from "../../../Shared/CustomCheckboxFilter";
import { Edit32 } from "@bphxd/ds-core-react/lib/icons";
import CreateQuestion from "../../ManageQuestions/CreateQuestion";
import { Messages } from "../../../../Constants";


const QuestionTab = ({ create }) => {
    const { t } = useTranslation();
    const { moduleData, moduleLanguagePreference, moduleDataSaved, moduleRefetch, globalUser } = useModuleContext();
    const pageSize = 10;
    const [questionColumnHeadings, setQuestionColumnHeadings] = useState([]);
    const [questionGridData, setQuestionGridData] = useState([]);
    const [editModalUPK, setEditModalUPK] = useState(false);
    const [createModalUPK, setCreateModalUPK] = useState(false);
    const [ddlPUValQ, setddlPUValQ] = useState([]);
    const [ddlLocationValQ, setddlLocationValQ] = useState([]);
    const [ddlCountryValQ, setddlCountryValQ] = useState([]);
    const [ddlValVersionQ, setddlValVersionQ] = useState([]);
    const [ddlAnswerValQ, setddlAnswerValQ] = useState([]);
    const [ddlDrvCompValQ, setddlDrvCompValQ] = useState([]);
    const [ddlYNValQ, setddlYNValQ] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const isSite = moduleData[0].task_Site === "SITE" ? true : false;
    const ddlCurriculumSelected = moduleData[0].task_Type;
    const ddlPUSelected = moduleData[0].fk_PUID;
    const ddlCountrySelected = moduleData[0].fk_CountryID;
    const ddlLocationSelected = moduleData[0].fk_LocationID;
    const ddlVersionSelected = moduleData[0].task_Site;
    const langaugeRef = useRef(moduleLanguagePreference);

    const gridDefaultColDef = {
        flex: 1,
        minWidth: 50,
        sortable: true,
        filter: true,
        resizable: true,
        editable: false,
        wrapText: true,
        autoHeight: true,
        suppressHeaderMenuButton: true,
        suppressHeaderContextMenu: true,
        sortingOrder: ["asc", "desc"],
        filterParams: {
            buttons: ["clear"],
            newRowsAction: "keep",
        },
        menuTabs: ["filterMenuTab"],
    }

    useEffect(() => {
        getAPIDataQuestion();
        setQuestionColumnHeadings([
            {
                headerName: "",
                field: "isAssigned",
                resizable: false,
                suppressMovable: true,
                minWidth: 80,
                maxWidth: 80,
                sortable: false,
                filter: CustomCheckboxFilter,
                cellRenderer: function (params) {
                    // Determine the initial value for `checkboxDisabled`
                    let checkboxDisabled = false;

                    // Access data from `params` and `moduleData` to set `checkboxDisabled`
                    const data = params.node.data;

                    if (data.task_Site === "SITE" && data.taskID !== data.global_TaskID && data.global_TaskID !== "GBL10058") {
                        checkboxDisabled = true;
                    }
                    if (moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type === "TSK" && langaugeRef.current === "en-GB") {
                        checkboxDisabled = true;
                    }
                    if (moduleData[0].task_Site === "GLOBAL" && globalUser !== "GADMIN" && moduleData[0].task_Type !== "TSK" && langaugeRef.current === "en-GB") {
                        checkboxDisabled = true;
                    }
                    if (moduleData[0].task_Site === "GLOBAL" && globalUser === "GADMIN" && moduleData[0].task_Type !== "TSK" && langaugeRef.current === "en-GB") {
                        checkboxDisabled = false;
                    }
                    if (moduleData[0].task_Site === "GLOBAL" && langaugeRef.current !== "en-GB") {
                        checkboxDisabled = true;
                    }

                    return (
                        <Input
                            type="checkbox"
                            checked={params.value === 'true'}
                            onChange={(event) => {
                                const checkboxSetValue = event.target.checked ? 'true' : 'false';
                                params.node.setDataValue('isAssigned', checkboxSetValue);
                                assignQuestiontoTaskClick(data, event.target.checked);
                            }}
                            disabled={checkboxDisabled} // Set the disabled prop here
                        />
                    );
                }

            },
            {
                headerName: t("Curriculumpage.QuestionsHeader.QUESTIONS"),
                headerTooltip: t("Curriculumpage.QuestionsHeader.QUESTIONS"),
                field: "question_Description",
                minWidth: 1000,
                maxWidth: 1400,
                filter: true,
                sort: "asc",
                resizable: false,
                suppressMovable: true,
            },
            {
                headerName: t("Curriculumpage.QuestionsHeader.MULTIPLE_CHOICE"),
                headerTooltip: t("Curriculumpage.QuestionsHeader.MULTIPLE_CHOICE"),
                field: "multiple_Choice",
                suppressMovable: true,
                minWidth: 200,
                filter: true,
                maxWidth: 200,
                resizable: false,
            },
            {
                headerName: t("Questionpage.LandingGridHeader.VERSION"),
                headerTooltip: t("Questionpage.LandingGridHeader.VERSION"),
                field: "site",
                sort: "asc",
                minWidth: 200,
                filter: true,
                maxWidth: 200,
                suppressMovable: true,
                resizable: false,
            },
            {
                headerName: t("Questionpage.LandingGridHeader.EDIT"),
                headerTooltip: t("Questionpage.LandingGridHeader.EDIT"),
                resizable: false,
                suppressMovable: true,
                minWidth: 100,
                sortable: false,
                cellClass: "text-center",
                headerClass: 'ag-center-header',
                filter: false,
                suppressHeaderMenuButton: true, cellRenderer: NewsEditCellRenderer,
            },
        ]);
        // eslint-disable-next-line
    }, []);

    const getAPIDataQuestion = () => {
        base.getAll("performanceunit/Qry").then((res) => {
            setddlPUValQ(res);
        });
        base.getAll("Location/Qry").then((res) => {
            setddlLocationValQ(res);
        });
        base.getAll("Country/Qry").then((res) => {
            setddlCountryValQ(res);
        });
        base.getAll("drop/frm/ANS").then((res) => {
            setddlAnswerValQ(res);
        });
        base.getAll("drop/frm/GBL_QTN").then((res) => {
            setddlValVersionQ(res);
        });
        base.getAll("drop/frm/D_COMP").then((res) => {
            setddlDrvCompValQ(res);
        });
        base.getAll("drop/frm/YORN").then((res) => {
            setddlYNValQ(res);
        });
    };

    const editQuestionClick = (Question) => {
        base.getAll(`Question/frm/${Question.questionID}`).then((response) => {
            if (response !== undefined) {
                setQuestionData(response);
                setEditModalUPK(true);
            }
        });
    };

    function NewsEditCellRenderer(params) {
        let disableEdit = "";
        // if (params.data.multiple_Choice === "N" && langaugeRef.current !== "en-GB") {
        //     disableEdit = "dat2DisableStepsActivityIcons";
        // }

        return (
            <div className=" gridcellicons1">
                <Edit32
                    onClick={() => editQuestionClick(params.node.data)}
                    className={"pointer " + disableEdit} />
            </div>
        );
    }

    // const NewCheckboxCellRenderer = ({ data }) => {
    //     const [checked, setChecked] = useState(data.isAssigned === "true" ? true : false);
    //     const handleChange = () => {
    //         setChecked(!checked); // Toggle the checked state
    //         assignQuestiontoTaskClick(data);
    //     };
    //     let checkboxDisabled
    //     if (data.task_Site === "SITE" && data.taskID !== data.global_TaskID && data.global_TaskID !== "GBL10058") {
    //         checkboxDisabled = true;
    //     }
    //     // if (data.task_Site === "GLOBAL" && globalUser !== "GADMIN") {
    //     //     checkboxDisabled = true;
    //     // }
    //     if (moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type === "TSK" && langaugeRef.current === "en-GB") {
    //         checkboxDisabled = true;
    //     }
    //     if (moduleData[0].task_Site === "GLOBAL" && globalUser !== "GADMIN" && moduleData[0].task_Type !== "TSK" && langaugeRef.current === "en-GB") {
    //         checkboxDisabled = true;
    //     }
    //     if (moduleData[0].task_Site === "GLOBAL" && globalUser === "GADMIN" && moduleData[0].task_Type !== "TSK" && langaugeRef.current === "en-GB") {
    //         checkboxDisabled = false;
    //     }
    //     if (moduleData[0].task_Site === "GLOBAL" && langaugeRef.current !== "en-GB") {
    //         checkboxDisabled = true;
    //     }

    //     return (
    //         <Input
    //             type="checkbox"
    //             id={data.questionID}
    //             disabled={checkboxDisabled}
    //             checked={checked}
    //             onChange={handleChange}
    //         />
    //     );
    // };


    const assignQuestionOnSave = (params) => {
        let newQuestion = params
        let body =
        {
            "fk_questionID": newQuestion,
            "fk_TaskID": moduleData[0].taskID
        }
        let assign = "TRUE";
        base.update(`questiontask/upd/${assign}`, body).then((res) => {
            getAllQuestions();
        }).catch((error) => {
            console.log("error updating question", error);
        });
    }

    const assignQuestiontoTaskClick = (params, checked) => {
        let updatedQuestion = params.questionID
        let body =
        {
            "fk_questionID": updatedQuestion,
            "fk_TaskID": params.taskID
        }

        let assign = checked === true ? "TRUE" : "FALSE";
        base.update(`questiontask/upd/${assign}`, body)
            .catch((error) => {
                console.log("error updating question", error);
            });

    };

    function convertUnicode(input) {
        if (input != null) {
            return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
            });
        }
    }

    const dataNotSaved = () => {
        moduleDataSaved("error", "Failed to create question")
    };

    const getAllQuestions = async () => {
        if (create === false) {
            var taskID = moduleData[0].taskID;
            if (taskID !== "") {
                let promises = []

                promises.push(base.getAll("question/qry/" + taskID + "/" + moduleLanguagePreference));
                promises.push(base.getAll("questiontask/qry/" + taskID));

                Promise.all(promises).then(([questions, assigned]) => {
                    if (questions !== undefined) {
                        var data = questions.filter(
                            (x) =>
                                x.rel_rec !== 0 &&
                                x.question_Description !== "" &&
                                x.question_Description != null
                        );

                        //for TSK type allow only MCQ type of question for assignment
                        //If any assigned descriptive question will be unassigned then it will disaapear from list
                        if (moduleData[0].task_Type === "TSK") {
                            data = data.filter(
                                (x) =>
                                    x.multiple_Choice.toUpperCase() === "Y" ||
                                    (x.multiple_Choice.toUpperCase() === "N" &&
                                        assigned.includes(x.questionID))
                            );
                        }

                        for (let i = 0; i < data.length; i++) {
                            data[i].question_Description = convertUnicode(
                                data[i].question_Description
                            );
                            data[i].taskID = moduleData[0].taskID;
                            data[i].global_TaskID = moduleData[0].global_TaskID;
                            data[i].task_Type = moduleData[0].task_Type;
                            data[i].task_Site = moduleData[0].task_Site;
                        }

                        let newData = data.map((question) => {
                            return {
                                ...question,
                                isAssigned: assigned.includes(question.questionID)
                                    ? "true"
                                    : "false",
                            };
                        });
                        if (moduleData[0].task_Site === "SITE" && moduleData[0].taskID !== moduleData[0].global_TaskID && moduleData[0].global_TaskID !== "GBL10058") {
                            newData = newData.filter(d => d.isAssigned === "true")
                        }
                        if (moduleData[0].task_Type === "TSK" && moduleData[0].task_Site === "GLOBAL") {
                            newData = newData.filter(d => d.isAssigned === "true")
                        }
                        if (globalUser !== "GADMIN" && moduleData[0].task_Type === "SYL" && moduleData[0].task_Site === "GLOBAL") {
                            newData = newData.filter(d => d.isAssigned === "true")
                        }
                        setQuestionGridData(newData);

                    }

                })
            }
        }
    };
    const closeCreateManageQuestion = (val) => {
        setCreateModalUPK(val);
        setEditModalUPK(val);
    };

    useEffect(() => {
        getAllQuestions(false)
        langaugeRef.current = moduleLanguagePreference
        // eslint-disable-next-line
    }, [moduleLanguagePreference, moduleRefetch]);

    const CASOpenClick = () => {
        setCreateModalUPK(true);
    };

    let isDisabled;
    if (moduleData[0].global_TaskID === "GBL10058") {
        if (globalUser === "GADMIN") {
            isDisabled = false;
        } else {
            isDisabled = true;
        }
    } else if (moduleData[0].task_Site === "GLOBAL") {
        if (globalUser === "GADMIN") {
            if (moduleData[0].task_Type === "TSK") {
                isDisabled = true;
            } else {
                isDisabled = false;
            }
        } else {
            isDisabled = true;
        }
    }
    if (moduleData[0].task_Site === "SITE" && moduleData[0].taskID !== moduleData[0].global_TaskID) {
        isDisabled = true;
    }

    return (
        <div className="ms-3 me-3  mt-0">
            <div className="text-end mb-2 mt-2">
                <Button
                    className="rounded-pill"
                    color="standard-primary"
                    size="sm"
                    onClick={() => CASOpenClick()}
                    disabled={isDisabled}                >
                    {t("Curriculumpage.Create Question")}
                </Button>{" "}
            </div>
            <div className="gridDiv">
                <div
                    className="ag-theme-material gridHtWd "
                    style={{ position: 'relative' }}
                >
                    <AgGridReact
                        columnDefs={questionColumnHeadings}
                        rowData={questionGridData}
                        suppressMenuHide={true}
                        overlayNoRowsTemplate="No records found"
                        overlayLoadingTemplate={"<span></span>"}
                        pagination={true}
                        paginationPageSizeSelector={[10, 20, 50]}
                        paginationPageSize={pageSize}
                        suppressScrollOnNewData={true}
                        resizeable={true}
                        animateRows={true}
                        defaultColDef={gridDefaultColDef}
                    />
                </div>
            </div>
            {createModalUPK === true && (
                <CreateQuestion
                    isSite={isSite}
                    PUList={ddlPUValQ}
                    LocationList={ddlLocationValQ}
                    CountryList={ddlCountryValQ}
                    AnswerList={ddlAnswerValQ}
                    GLList={ddlValVersionQ}
                    YesNoList={ddlYNValQ}
                    DrivingCompList={ddlDrvCompValQ}
                    create={true}
                    modalOpen={createModalUPK}
                    saveSuccess={() => {
                        moduleDataSaved(
                            "dataSuccess",
                            Messages.manageCurriCulum.questionCreated
                        );
                    }}
                    error={dataNotSaved}
                    getAll={getAllQuestions}
                    assignQuestionOnSave={assignQuestionOnSave}
                    close={closeCreateManageQuestion}
                    fromtask={true}
                    version={ddlVersionSelected}
                    PUSelected={ddlPUSelected}
                    CountrySelected={ddlCountrySelected}
                    LocationSelected={ddlLocationSelected}
                    questionType={ddlCurriculumSelected === "TSK" ? "UPK" : ddlCurriculumSelected === "SYL" ? "CAS" : ddlCurriculumSelected}
                    questionData={[]}
                ></CreateQuestion>
            )
            }
            {editModalUPK === true && (
                <CreateQuestion
                    isSite={isSite}
                    PUList={ddlPUValQ}
                    LocationList={ddlLocationValQ}
                    CountryList={ddlCountryValQ}
                    AnswerList={ddlAnswerValQ}
                    GLList={ddlValVersionQ}
                    YesNoList={ddlYNValQ}
                    DrivingCompList={ddlDrvCompValQ}
                    create={false}
                    modalOpen={editModalUPK}
                    saveSuccess={() => {
                        moduleDataSaved(
                            "dataSuccess",
                            Messages.manageCurriCulum.questionCreated
                        );
                    }}
                    error={dataNotSaved}
                    getAll={getAllQuestions}
                    close={closeCreateManageQuestion}
                    fromtask={true}
                    version={ddlVersionSelected}
                    PUSelected={ddlPUSelected}
                    CountrySelected={ddlCountrySelected}
                    LocationSelected={ddlLocationSelected}
                    questionType={ddlCurriculumSelected === "TSK" ? "UPK" : ddlCurriculumSelected === "SYL" ? "CAS" : ddlCurriculumSelected}
                    questionData={questionData}
                    moduleLanguagePreference={moduleLanguagePreference}
                ></CreateQuestion>
            )
            }
        </div>
    );
}

export default QuestionTab;
