import React, { useState, useEffect } from 'react';
import { Col, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { World24, Info24 } from '@bphxd/ds-core-react/lib/icons';
import Select, { components } from "react-select";
import { useUser } from "../../Shared/userContext";
import base from "../../../Services/BaseService";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';

const CreateQuestion = (props) => {
    const { languages, userData, languagePreference, globalUser } = useUser();
    const englishID = "en-GB";
    const { t } = useTranslation();
    const { userLevel } = userData;

    const englishQuestion = props.questionData.filter(d => d.fk_LanguageID === englishID);
    const isMCQ = props.create ? props.questionType === "DAT" ? false : true : englishQuestion[0]?.multiple_Choice === "Y" ? true : false
    const [version, setVersion] = useState(props.fromtask && props.create ? props.isSite ? "SITE" : "GLOBAL" : props.create ? (userLevel === "10" || userLevel === "15" || userLevel === "20") ? "" : "SITE" : props.questionData[0]?.site)
    const [language, setLanguage] = useState(props.fromtask && !props.create ? props.moduleLanguagePreference : props.create ? englishID : isMCQ ? languagePreference : englishID)
    const [questionData, setQuestionData] = useState(props.create
        ? [{
            "questionID": "",
            "q_UPK": "",
            "q_CA": "",
            "q_DAT": "",
            "question_Description": "",
            "modelAnswer": "",
            "optionA": "",
            "optionB": "",
            "optionC": "",
            "optionD": "",
            "compliance": "",
            "nonCompliance": "",
            "drivingCompetency": "",
            "answer": "",
            "active": "Y",
            "site": props.fromtask ? props.isSite ? "SITE" : "GLOBAL" : version,
            "fk_PUID": props.PUSelected,
            "fk_CountryID": props.CountrySelected,
            "fk_LocationID": props.LocationSelected,
            "question_Type": props.questionType,
            "multiple_Choice": props.questionType === "DAT" ? "N" : "Y",
            "fk_LanguageID": language,
            "isCriticalQuestion": false
        }]
        : props.questionData);
    const [selectedQuestion, setSelectedQuestion] = useState(questionData.filter(d => d.fk_LanguageID === language));
    const [adminFieldsDisbaled, setAdminFieldsDisbaled] = useState("");
    const [masterEdit, setMasterEdit] = useState("")
    const [region, setRegion] = useState(props.fromtask && props.create ? (props.PUSelected || '') + (props.CountrySelected || '') + (props.LocationSelected || '') : selectedQuestion[0]?.fk_PUID !== "" ? selectedQuestion[0]?.fk_PUID : selectedQuestion[0]?.fk_CountryID !== "" ? selectedQuestion[0]?.fk_CountryID : selectedQuestion[0]?.fk_LocationID)

    var versionOptions = [];
    if (userLevel <= 20) {
        if (props.questionType !== "UPK") {
            versionOptions = [{ label: "Global", value: "GLOBAL" }, { label: "Local", value: "SITE" }];
        } else {
            versionOptions = [{ label: "Local", value: "SITE" }];
        }
    }
    else {
        versionOptions = [{ label: "Local", value: "SITE" }];
    }

    const ddlDrvCompVal = props.DrivingCompList.map(item => ({
        value: item.pValue1,
        label: item.pValue2
    }));

    const regionOptions = (() => {
        let options = []
        let pu = props.PUList.map(item => ({
            value: item.puid,
            label: item.pU_Description,
            category: "pu"
        }));
        let country = props.CountryList.map(item => ({
            value: item.countryID,
            label: item.country_Description,
            category: "country"
        }));
        let location = props.LocationList.map(item => ({
            value: item.locationID,
            label: item.location_Description,
            category: "location"
        }));
        options = options.concat(pu, country, location);
        return options;
    })();

    const [correctAnswers, setCorrectAnswers] = useState(selectedQuestion[0]?.answer === null ? "" : selectedQuestion[0]?.answer)
    const [drivingCompetency, setDrivingCompetency] = useState(selectedQuestion[0]?.drivingCompetency === null ? "" : selectedQuestion[0]?.drivingCompetency)
    const [critical, setCritical] = useState(selectedQuestion[0]?.isCriticalQuestion === null ? "" : selectedQuestion[0]?.isCriticalQuestion)
    const [invalid, setInvalid] = useState([])

    const handleLanguageChange = (event) => {
        let selectedLanguage = event.target.value
        setLanguage(selectedLanguage);
        setSelectedQuestion(props.questionData.filter(d => d.fk_LanguageID === selectedLanguage));
    };

    useEffect(() => {
        if (props.fromtask) {
            setLanguage(props.fromtask && !props.create ? props.moduleLanguagePreference : englishID);
        } else {
            setLanguage(props.create ? englishID : isMCQ ? languagePreference : englishID);
        }
    }, [props.moduleLanguagePreference, props.fromtask, languagePreference, props.create, isMCQ]);


    const handleVersionChange = (e) => {
        setVersion(e);
        if (e === "GLOBAL") {
            setRegion("");
        }
        setQuestionData(prevQuestionData => {
            const updatedQuestionData = prevQuestionData.map(question => ({
                ...question,
                site: e,
                fk_LocationID: e === "GLOBAL" ? "" : question.fk_LocationID,
                fk_CountryID: e === "GLOBAL" ? "" : question.fk_CountryID,
                fk_PUID: e === "GLOBAL" ? "" : question.fk_PUID,
            }));
            return updatedQuestionData;
        });
    }

    const handleCheckboxClick = (key) => {
        const isInvalid = invalid.includes(key);
        const itemChecked = document.getElementById(key).checked;

        if (isInvalid) {
            setInvalid((prev) => {
                return prev.filter((d) => d !== key);
            });
        }

        let item = questionData.find(item => item.fk_LanguageID === language)

        var newResponse = "";
        var prevResponse = "";

        prevResponse = item.answer;

        if (itemChecked) {
            newResponse = key[key.length - 1];
            if (prevResponse !== "") {
                const originalArray = prevResponse.split(';');
                originalArray.push(newResponse);
                const sortedArray = originalArray.sort();
                const sortedString = sortedArray.join(';');
                newResponse = sortedString;
            }
        } else {
            newResponse = prevResponse
                .split(';')
                .filter(item => item !== key[key.length - 1])
                .join(';');
        }

        setQuestionData(prevQuestionData => {
            const updatedQuestionData = prevQuestionData.map(question => ({
                ...question,
                answer: newResponse
            }));

            return updatedQuestionData;
        });
        setCorrectAnswers(newResponse);
    };


    const handleCriticalCheckbox = (key) => {
        const itemChecked = document.getElementById(key).checked;
        setCritical(itemChecked);

        setQuestionData(prevQuestionData => {
            const updatedQuestionData = prevQuestionData.map(question => ({
                ...question,
                isCriticalQuestion: itemChecked
            }));

            return updatedQuestionData;
        });
    };

    useEffect(() => {
        const uniqueLocns = [...new Set(userData.location.map((item) => item.fk_LocationID))];
        setMasterEdit(() => {
            let edit = false;
            if (selectedQuestion[0]?.site !== "GLOBAL") {
                edit = true
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && language !== englishID && userLevel !== "40") {
                edit = true
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && selectedQuestion[0].question_Type === "UPK" && language === englishID) {
                edit = false
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && globalUser === "GADMIN" && selectedQuestion[0].question_Type !== "UPK" && language === englishID) {
                edit = true
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && globalUser !== "GADMIN" && selectedQuestion[0].question_Type !== "UPK" && language === englishID) {
                edit = false
            }
            if (userLevel === "40" && !uniqueLocns.includes(selectedQuestion[0]?.fk_LocationID)) {
                edit = false
            }
            return edit;
        })
        setAdminFieldsDisbaled(() => {
            let disabled = true;
            if (selectedQuestion[0]?.site !== "GLOBAL" && isMCQ) {
                disabled = false
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && isMCQ && selectedQuestion[0].question_Type !== "UPK" && language !== englishID && userLevel !== "40") {
                disabled = false
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && isMCQ && selectedQuestion[0].question_Type === "UPK" && language !== englishID) {
                disabled = true
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && globalUser !== "GADMIN" && isMCQ && selectedQuestion[0].question_Type !== "UPK") {
                disabled = true
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && globalUser === "GADMIN" && isMCQ && selectedQuestion[0].question_Type !== "UPK" && language === englishID) {
                disabled = false
            }
            if (selectedQuestion[0]?.site === "GLOBAL" && isMCQ && selectedQuestion[0].question_Type === "UPK" && language === englishID) {
                disabled = true
            }
            if (userLevel === "40" && !uniqueLocns.includes(selectedQuestion[0]?.fk_LocationID)) {
                disabled = true
            }
            return disabled;
        })
    }, [language, selectedQuestion, isMCQ, userLevel, globalUser, userData.location]);


    const handleSave = async () => {
        let fail = [];
        let correctAnswers = questionData[0].answer;
        let cEmpty = (questionData[0]?.optionC || '') === '' ? true : false;
        let dEmpty = (questionData[0]?.optionD || '') === '' ? true : false;

        questionData.map((item) => {
            switch (props.questionType) {
                case "UPK":
                case "CAS":
                    if (item.question_Description === "") {
                        fail.push("question_Description");
                    }
                    if (isMCQ) {
                        if (item.optionA === "") {
                            fail.push("optionA");
                        }
                        if (item.optionB === "") {
                            fail.push("optionB");
                        }
                        if (((item?.optionC || '') === '') !== cEmpty) {
                            fail.push("optionC");
                        }
                        if ((item?.optionC || '') === '' && correctAnswers?.split(';').includes("C")) {
                            fail.push("optionC");
                        }
                        if (((item?.optionD || '') === '') !== dEmpty) {
                            fail.push("optionD");
                        }
                        if ((item?.optionD || '') !== '' && (item?.optionC || '') === '') {
                            fail.push("optionC");
                        }
                        if ((item?.optionD || '') === '' && correctAnswers?.split(';').includes("D")) {
                            fail.push("optionD");
                        }
                        if (item.answer === "") {
                            fail.push("oA");
                            fail.push("oB");
                        }
                    } else {
                        if (item.modelAnswer === "") {
                            fail.push("modelAnswer");
                        }
                    }
                    break;
                case "DAT":
                    if (item.question_Description === "") {
                        fail.push("question_Description");
                    }
                    if (item.compliance === "") {
                        fail.push("compliance");
                    }
                    if (item.nonCompliance === "") {
                        fail.push("nonCompliance");
                    }
                    break;
                default:
                    //no action
                    break;
            }
            return null; // return a value to satisfy the map function, but it's not used
        });

        if (version === "") {
            fail.push("Site");
        }
        if (version === "SITE" && region === "") {
            fail.push("regionDropdown");
        }
        if (props.questionType === "DAT" && drivingCompetency === "") {
            fail.push("drivingCompetency");

        }
        setInvalid(fail);
        if (fail.length === 0) {
            //process save
            if (props.create) {
                base.add("question/addquestion", questionData[0])
                    .then((res) => {
                        if (props.fromtask) {
                            props.assignQuestionOnSave(res.data.questionID)
                        } else {
                            props.getAll();
                        }
                        props.saveSuccess()
                        props.close();
                    })
                    .catch(error => {
                        props.close();
                        props.error();
                        console.error('Error adding question:', error);
                    });
            } else {
                base.update("question/upd", questionData)
                    .then(() => {
                        props.getAll();
                        props.saveSuccess()
                        props.close();
                    })
                    .catch(error => {
                        props.close();
                        props.error();
                        console.error('Error updating question:', error);
                    });
            }
        }
    }

    const handleChange = (e) => {
        let elementValue = e.target.value
        let elementID = e.target.id
        setInvalid(prevInvalid =>
            prevInvalid.filter(element => element !== elementID)
        );
        setQuestionData((prevQuestionData) => {
            const updatedLanguageID = language;
            const updatedItemIndex = prevQuestionData.findIndex(item => item.fk_LanguageID === updatedLanguageID);
            if (updatedItemIndex !== -1) {
                const updatedItem = {
                    ...prevQuestionData[updatedItemIndex],
                    [elementID]: elementValue,

                };
                const updatedQuestionData = [
                    ...prevQuestionData.slice(0, updatedItemIndex),
                    updatedItem,
                    ...prevQuestionData.slice(updatedItemIndex + 1)
                ];
                return updatedQuestionData;
            }
            return prevQuestionData;
        });

    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
        }),
    };

    return (
        <Modal isOpen={props.modalOpen} className="modal-dialog-centered modal-70">
            <Modal.Header
                className="comm-header-bgPosition"
                onToggleOpen={() => {
                    props.close();
                }}>
                <Label className="modalHeaderGlobal m-4">
                    {t(`Questionpage.${props.create ? "" : "Edit "}${props.questionType}`)}
                </Label>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xl="12">
                        <Row>
                            <Col xs="12" md="6" className="">
                                <Label>
                                    {t("Questionpage.Version")} <span className="text-danger">*</span>
                                </Label>

                                <Select
                                    id="Site"
                                    name="Site"
                                    placeholder={version !== "" ? version === "GLOBAL" ? "Global" : "Local" : ""}
                                    value={versionOptions.find((option) => option.value === version)}
                                    options={versionOptions}
                                    styles={customStyles}
                                    isDisabled={props.create && !props.fromtask ? false : true}
                                    classNamePrefix="react-select"
                                    onChange={(e) => {
                                        handleVersionChange(e.value);
                                        setInvalid((prev) => {
                                            return prev.filter((d) => d !== "Site");
                                        });
                                    }}
                                    invalid={invalid.includes(`Site`)}

                                ></Select>
                                <div
                                    className="errMsgCAS "
                                    id="errorVersion"
                                    style={{ display: "none" }}
                                >
                                    {t("Questionpage.VersionError")}
                                </div>

                            </Col>
                            {props.questionType === "DAT" ?
                                <Col xs="12" md="6" className="mt-xs-0 mt-md-0">
                                    <Label>
                                        {t("Questionpage.Driving Competency")} <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="drivingCompetency"
                                        name="drivingCompetency"
                                        placeholder={""}
                                        value={ddlDrvCompVal.find((option) => option.value === questionData.find(item => item.fk_LanguageID === language)?.drivingCompetency || '')}
                                        options={ddlDrvCompVal}
                                        isDisabled={!props.create}
                                        styles={customStyles}
                                        onChange={(e) => {
                                            setDrivingCompetency(e.value);
                                            setInvalid((prev) => {
                                                return prev.filter((d) => d !== "drivingCompetency");
                                            });
                                            setQuestionData(prevQuestionData => {
                                                const updatedQuestionData = prevQuestionData.map(question => ({
                                                    ...question,
                                                    drivingCompetency: e.value
                                                }));

                                                return updatedQuestionData;
                                            });
                                        }}
                                        classNamePrefix="react-select"
                                        invalid={invalid.includes(`drivingCompetency`)}

                                    ></Select>
                                </Col>

                                :
                                <Col xs="12" md="6" className="mt-xs-0 mt-md-0">
                                    <Col>
                                        <Input
                                            className="mt-sm-6"
                                            id="isCriticalQuestion"
                                            type={"checkbox"}
                                            disabled={props.questionType === "UPK" || props.questionType === "CAS" ? isMCQ ? adminFieldsDisbaled : true : true}
                                            onChange={() => handleCriticalCheckbox("isCriticalQuestion")}
                                            checked={critical}
                                        ></Input>
                                        <Label

                                            for="critical"
                                            style={{
                                                position: "relative",
                                                marginRight: "4px",
                                                whiteSpace: "nowrap",
                                                cursor: "pointer",

                                            }}
                                            className="ms-3 mt-sm-6 mt-3 pt-1 pt-sm-0"
                                        >
                                            {t("Questionpage.Critical")}

                                        </Label>
                                        <span>
                                            <Info24
                                                id='criticalPopover'
                                            ></Info24>
                                            <UncontrolledPopover
                                                placement="right"
                                                target="criticalPopover"
                                                trigger="hover focus"
                                                popperClassName="popover"
                                            >
                                                <PopoverBody className="tooltip-msg">
                                                    {t("Questionpage.CriticalQusInfoMsg")}
                                                </PopoverBody>
                                            </UncontrolledPopover>

                                        </span>
                                    </Col>
                                </Col>
                            }
                            {(version !== "GLOBAL" && version !== "") && (
                                <>
                                    <Col xs="12" md="6" className="mt-2">
                                        <Label>{t("Questionpage.Region")}</Label>
                                        <Select
                                            id="regionDropdown"
                                            isDisabled={props.fromtask ? props.fromtask : version === "SITE" ? false : true}
                                            options={regionOptions}
                                            components={{
                                                Option: (props) => (
                                                    <div id=" ">
                                                        <components.Option {...props}>
                                                            <div
                                                                style={{
                                                                    display: "grid",
                                                                    gridTemplateColumns: "15% 85%",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <span> {props.value}</span>
                                                                <span> {props.label}</span>
                                                            </div>
                                                        </components.Option>
                                                    </div>
                                                ),
                                                IndicatorSeparator: () => null,

                                            }}
                                            placeholder={""}
                                            classNamePrefix="react-select"
                                            value={regionOptions.find((option) => option.value === region)}
                                            onChange={(e) => {
                                                setRegion(e);
                                                setInvalid((prev) => {
                                                    return prev.filter((d) => d !== "regionDropdown");
                                                });
                                                setQuestionData(prevQuestionData => {
                                                    const updatedQuestionData = prevQuestionData.map(question => ({
                                                        ...question,
                                                        fk_CountryID: e.category === "country" ? e.value : "",
                                                        fk_LocationID: e.category === "location" ? e.value : "",
                                                        fk_PUID: e.category === "pu" ? e.value : ""
                                                    }));

                                                    return updatedQuestionData;
                                                });
                                            }}

                                            styles={customStyles}
                                            invalid={invalid.includes(`regionDropdown`)}


                                        ></Select>
                                    </Col>
                                </>
                            )
                            }

                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="mt-2">
                        <Row>
                            <Col>
                                <Label htmlFor="questionLable">
                                    {t("Questionpage.Question")} <span className="text-danger">*</span>
                                </Label>
                            </Col>
                            {(version === "GLOBAL" && language !== englishID) && (
                                <Col className="text-end">
                                    <span>
                                        <Info24
                                            id='questionPopover'
                                        ></Info24>
                                        <UncontrolledPopover
                                            placement="top"
                                            target="questionPopover"
                                            trigger="hover focus"
                                            popperClassName="popover"
                                        >
                                            <PopoverBody className="tooltip-msg">
                                                {questionData.find(item => item.fk_LanguageID === englishID)?.question_Description}
                                            </PopoverBody>
                                        </UncontrolledPopover>

                                    </span>
                                </Col>
                            )
                            }
                        </Row>

                        <Input
                            type="textarea"
                            id="question_Description"
                            name="question_Description"
                            className=""
                            maxLength={255}
                            style={{ height: "68px" }}
                            value={questionData.find(item => item.fk_LanguageID === language)?.question_Description || ''}
                            onChange={handleChange}
                            invalid={invalid.includes(`question_Description`)}
                            disabled={(props.create) ? false : !masterEdit}

                        ></Input>
                        <Row>
                            <Col className="text-end">
                                <Label style={{ fontSize: " 12px" }}>
                                    {questionData.find(item => item.fk_LanguageID === language)?.question_Description?.length ?? 0}/255
                                </Label>
                            </Col>
                        </Row>
                    </Col>
                    {!isMCQ ?
                        props.questionType === "DAT" ?
                            <>
                                <Col xs="12" className="mt-2">
                                    <Row>
                                        <Col>
                                            <Label htmlFor="Complaince" style={{ width: "275px" }}>
                                                {t("Questionpage.Compliance Statement")}<span className="text-danger">*</span>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        type="textarea"
                                        id="compliance"
                                        name="compliance"
                                        className=""
                                        style={{ height: "75px" }}
                                        maxLength={255}
                                        value={questionData.find(item => item.fk_LanguageID === language)?.compliance || ''}
                                        invalid={invalid.includes(`compliance`)}
                                        onChange={handleChange}
                                        disabled={(props.create) ? false : !masterEdit}

                                    />
                                    <Row>
                                        <Col className="text-end">
                                            <Label style={{ fontSize: " 12px" }}>
                                                {questionData.find(item => item.fk_LanguageID === language)?.compliance?.length ?? 0}/255
                                            </Label>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="12" className="mt-2">
                                    <Row>
                                        <Col>
                                            <Label htmlFor="nonCompliance" style={{ width: "275px" }}>
                                                {t("Questionpage.Non-Compliant")} <span className="text-danger">*</span>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        type="textarea"
                                        id="nonCompliance"
                                        name="nonCompliance"
                                        className=""
                                        style={{ height: "75px" }}
                                        maxLength={255}
                                        value={questionData.find(item => item.fk_LanguageID === language)?.nonCompliance || ''}
                                        invalid={invalid.includes(`nonCompliance`)}
                                        onChange={handleChange}
                                        disabled={(props.create) ? false : !masterEdit}

                                    />
                                    <Row>
                                        <Col className="text-end">
                                            <Label style={{ fontSize: " 12px" }}>
                                                {questionData.find(item => item.fk_LanguageID === language)?.nonCompliance?.length ?? 0}/255
                                            </Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                            :
                            <>
                                <Col xs="12" className="mt-2">
                                    <Row>
                                        <Col>
                                            <Label htmlFor="OptionA" style={{ width: "275px" }}>
                                                {t("Questionpage.Model Answer")} <span className="text-danger">*</span>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        type="textarea"
                                        id="modelAnswer"
                                        name="modelAnswer"
                                        className=""
                                        style={{ height: "200px" }}
                                        maxLength={1050}
                                        value={questionData.find(item => item.fk_LanguageID === language)?.modelAnswer || ''}
                                        invalid={invalid.includes(`ModelAnswer`)}
                                        onChange={handleChange}
                                        disabled={(props.create) ? false : !masterEdit}

                                    />
                                    <Row>
                                        <Col className="text-end">
                                            <Label style={{ fontSize: " 12px" }}>
                                                {questionData.find(item => item.fk_LanguageID === language)?.modelAnswer?.length ?? 0}/1050
                                            </Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        :
                        <>
                            <Col xs="12" className="mt-2">
                                <Row>
                                    <Col>
                                        <Label htmlFor="optionA" style={{ width: "75px" }} >
                                            {t("Questionpage.Option A")} <span className="text-danger">*</span>
                                        </Label>

                                        <Label for="oA" className="ms-5" >
                                            {t("Questionpage.Correct Answer")}
                                        </Label>
                                        <Input
                                            id="oA"
                                            style={{ marginTop: "0px", marginLeft: "10px" }}
                                            type="checkbox"
                                            checked={correctAnswers?.split(';').includes("A")}
                                            invalid={invalid.includes(`oA`)}
                                            disabled={adminFieldsDisbaled}
                                            onChange={() => handleCheckboxClick("oA")}

                                        />
                                    </Col>
                                    {(version === "GLOBAL" && language !== englishID) && (
                                        <Col className="text-end">
                                            <span>
                                                <Info24
                                                    id='optionAPopover'
                                                ></Info24>
                                                <UncontrolledPopover
                                                    placement="top"
                                                    target="optionAPopover"
                                                    trigger="hover focus"
                                                    popperClassName="popover"
                                                >
                                                    <PopoverBody className="tooltip-msg">
                                                        {questionData.find(item => item.fk_LanguageID === englishID)?.optionA}
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                            </span>
                                        </Col>
                                    )
                                    }
                                </Row>
                                <Input
                                    type="textarea"
                                    id="optionA"
                                    name="optionA"
                                    className=""
                                    style={{ height: "68px" }}
                                    maxLength={300}
                                    value={questionData.find(item => item.fk_LanguageID === language)?.optionA || ''}
                                    invalid={invalid.includes(`optionA`)}
                                    onChange={handleChange}
                                    disabled={(props.create) ? false : !masterEdit}


                                />
                                <Row>
                                    <Col className="text-end">
                                        <Label style={{ fontSize: " 12px" }}>
                                            {questionData.find(item => item.fk_LanguageID === language)?.optionA?.length ?? 0}/300
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" className="mt-2">
                                <Row>
                                    <Col>
                                        <Label htmlFor="optionB" style={{ width: "75px" }} >
                                            {t("Questionpage.Option B")} <span className="text-danger">*</span>
                                        </Label>

                                        <Label for="oB" className="ms-5" >

                                            {t("Questionpage.Correct Answer")}
                                        </Label>
                                        <Input
                                            id="oB"
                                            style={{ marginTop: "0px", marginLeft: "10px" }}
                                            type="checkbox"
                                            checked={correctAnswers.split(';').includes("B")}
                                            invalid={invalid.includes(`oB`)}
                                            disabled={adminFieldsDisbaled}
                                            onChange={() => handleCheckboxClick("oB")}


                                        />
                                    </Col>
                                    {(version === "GLOBAL" && language !== englishID) && (
                                        <Col className="text-end">
                                            <span>
                                                <Info24
                                                    id='optionVPopover'
                                                ></Info24>
                                                <UncontrolledPopover
                                                    placement="top"
                                                    target="optionVPopover"
                                                    trigger="hover focus"
                                                    popperClassName="popover"
                                                >
                                                    <PopoverBody className="tooltip-msg">
                                                        {questionData.find(item => item.fk_LanguageID === englishID)?.optionB}
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                            </span>
                                        </Col>
                                    )
                                    }
                                </Row>
                                <Input
                                    type="textarea"
                                    id="optionB"
                                    name="optionB"
                                    className=""
                                    style={{ height: "68px" }}
                                    maxLength={300}
                                    value={questionData.find(item => item.fk_LanguageID === language)?.optionB || ''}
                                    onChange={handleChange}
                                    invalid={invalid.includes(`optionB`)}
                                    disabled={(props.create) ? false : !masterEdit}


                                />
                                <Row>
                                    <Col className="text-end">
                                        <Label style={{ fontSize: " 12px" }}>
                                            {questionData.find(item => item.fk_LanguageID === language)?.optionB?.length ?? 0}/300
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" className="mt-2">
                                <Row>
                                    <Col>
                                        <Label htmlFor="optionC" style={{ width: "75px" }} >
                                            {t("Questionpage.Option C")}<span className="text-danger"> </span>
                                        </Label>

                                        <Label for="oC" className="ms-5" >

                                            {t("Questionpage.Correct Answer")}
                                        </Label>
                                        <Input
                                            id="oC"
                                            style={{ marginTop: "0px", marginLeft: "10px" }}
                                            type="checkbox"
                                            checked={correctAnswers.split(';').includes("C")}
                                            invalid={invalid.includes(`oC`)}
                                            disabled={adminFieldsDisbaled}
                                            onChange={() => handleCheckboxClick("oC")}


                                        />
                                    </Col>
                                    {(version === "GLOBAL" && language !== englishID && questionData.find(item => item.fk_LanguageID === englishID)?.optionC !== "") && (
                                        <Col className="text-end">
                                            <span>
                                                <Info24
                                                    id='optionCPopover'
                                                ></Info24>
                                                <UncontrolledPopover
                                                    placement="top"
                                                    target="optionCPopover"
                                                    trigger="hover focus"
                                                    popperClassName="popover"
                                                >
                                                    <PopoverBody className="tooltip-msg">
                                                        {questionData.find(item => item.fk_LanguageID === englishID)?.optionC}
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                            </span>
                                        </Col>
                                    )
                                    }
                                </Row>
                                <Input
                                    type="textarea"
                                    id="optionC"
                                    name="optionC"
                                    className=""
                                    style={{ height: "68px" }}
                                    maxLength={300}
                                    value={questionData.find(item => item.fk_LanguageID === language)?.optionC || ''}
                                    onChange={handleChange}
                                    invalid={invalid.includes(`optionC`)}
                                    disabled={(props.create) ? false : masterEdit ? (((questionData.find(item => item.fk_LanguageID === englishID)?.optionC || '') === "") && language !== englishID && version !== "SITE") ? true : false : !masterEdit}


                                />
                                <Row>
                                    <Col className="text-end">
                                        <Label style={{ fontSize: " 12px" }}>
                                            {questionData.find(item => item.fk_LanguageID === language)?.optionC?.length ?? 0}/300
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" className="mt-2">
                                <Row>
                                    <Col>
                                        <Label htmlFor="optionD" style={{ width: "75px" }} >
                                            {t("Questionpage.Option D")}<span className="text-danger"> </span>
                                        </Label>

                                        <Label for="oD" className="ms-5" >

                                            {t("Questionpage.Correct Answer")}
                                        </Label>
                                        <Input
                                            id="oD"
                                            style={{ marginTop: "0px", marginLeft: "10px" }}
                                            type="checkbox"
                                            checked={correctAnswers.split(';').includes("D")}
                                            invalid={invalid.includes(`oD`)}
                                            disabled={adminFieldsDisbaled}
                                            onChange={() => handleCheckboxClick("oD")}

                                        />
                                    </Col>
                                    {(version === "GLOBAL" && language !== englishID && questionData.find(item => item.fk_LanguageID === englishID)?.optionD !== "") && (
                                        <Col className="text-end">
                                            <span>
                                                <Info24
                                                    id='optionDPopover'
                                                ></Info24>
                                                <UncontrolledPopover
                                                    placement="top"
                                                    target="optionDPopover"
                                                    trigger="hover focus"
                                                    popperClassName="popover"
                                                >
                                                    <PopoverBody className="tooltip-msg">
                                                        {questionData.find(item => item.fk_LanguageID === englishID)?.optionD}
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                            </span>
                                        </Col>
                                    )
                                    }
                                </Row>
                                <Input
                                    type="textarea"
                                    id="optionD"
                                    name="optionD"
                                    className=""
                                    style={{ height: "68px" }}
                                    maxLength={300}
                                    value={questionData.find(item => item.fk_LanguageID === language)?.optionD || ''}
                                    onChange={handleChange}
                                    disabled={(props.create) ? false : masterEdit ? (((questionData.find(item => item.fk_LanguageID === englishID)?.optionD || '') === "") && language !== englishID && version !== "SITE") ? true : false : !masterEdit}
                                    invalid={invalid.includes(`optionD`)}

                                />
                                <Row>
                                    <Col className="text-end">
                                        <Label style={{ fontSize: " 12px" }}>
                                            {questionData.find(item => item.fk_LanguageID === language)?.optionD?.length ?? 0}/300
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    }

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className="w-100" >
                    <Col xs="4">
                        {(!props.create && isMCQ) &&
                            <div className="d-flex" >
                                <World24 className="icon-margin" />
                                <Input type="select" className="border-0" value={language} onChange={handleLanguageChange}>
                                    {languages.map((item, index) => {
                                        return (
                                            <option key={index} value={item.languageID} >
                                                {item.language_Name}
                                            </option>
                                        )
                                    })
                                    }
                                </Input>
                            </div>
                        }
                    </Col>
                    <Col xs="8" className="text-end">
                        <Button className="rounded-pill btn-style"
                            size="sm"
                            color="standard-secondary"
                            onClick={() => props.close()}>
                            {t("Common.Cancel")}
                        </Button>{" "}
                        <Button className="rounded-pill btn-style"
                            size="sm"
                            color="standard-primary"
                            onClick={() => handleSave()}
                        >
                            {t("Common.Save")}
                        </Button>{" "}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateQuestion;
