import React from "react";
import { Outlet, useLocation } from "react-router-dom-v5-compat";
import Header from "../Header/HeaderNew";
import Footer from "../Footer/Footer";
import useActiveNavLink from "../../../Hooks/useActiveNavLink";
import { LoadingOverlayWrapperProvider } from "./loadingOverlay";
import { UserProvider } from "../userContext";
import { AssessmentProvider } from "../assessmentContext"
import { MyTrainingProvider } from "../myTrainingContext"

const AppLayout = (props) => {
  const location = useLocation();
  const activeNavLinkInfo = useActiveNavLink({
    currentRoute: location.pathname,
  });

  const { userData, logOut } = props;
  const { fk_EmployeeID } = userData;

  return (
    <>
      <UserProvider userData={userData} logOut={logOut}>
        <MyTrainingProvider fk_EmployeeID={fk_EmployeeID}>
          <AssessmentProvider>
            <div className="navbar-new">
              <div className="page-container ">
                <div className="content-wrap">
                  <Header activeNavLinkInfo={activeNavLinkInfo} />
                  <LoadingOverlayWrapperProvider>
                    <Outlet />
                  </LoadingOverlayWrapperProvider>
                </div>
                <Footer />
              </div>
            </div>
          </AssessmentProvider>
        </MyTrainingProvider>
      </UserProvider >
    </>
  );
};

export default AppLayout;
